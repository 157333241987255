import React from 'react'
import './commonlySearched.scss'
import SVGInline from 'react-svg-inline'
import ChevronDown from '../../shared/assets/chevron-down.svg'
import UpArrow from '../../shared/assets/up-arrow.svg'
import DownArrow from '../../shared/assets/down-arrow.svg'

export default class commonlySearchedBlock extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      csLinkToggle: window.innerWidth < 768
    }
    this.toggleLinks = this.toggleLinks.bind(this)
  }

  renderLinks () {
    let columns = []
    let chunkSize = Math.ceil(this.props.csItems.length / 4)
    for (var i = 0; i < this.props.csItems.length; i += chunkSize) {
      columns.push(this.props.csItems.slice(i, i + chunkSize))
    }
    return columns.map((column, index) => {
      return <div className='column small-12 medium-6 large-3 commonly-searched--terms-items' key={index}>
        <ul className='no-bullet'>
          {
            column.map((item, index) => {
              const fixedUrl = item.href.replace(/&#x2B;/g, '%20')
              return <li className='commonly-searched--terms-item' key={index}><a className='search-item' href={fixedUrl} target={item.target}>{item.label}</a></li>
            })
          }
        </ul>
      </div>
    })
  }

  toggleLinks () {
    this.setState(prevState => {
      return { csLinkToggle: !prevState.csLinkToggle }
    })
  }

  render () {
    return <div className='epi-block--wrapper commonly-searched'>
      <div className='epi-block--background' />
      <div className='commonly-searched--actuator-wrapper' onClick={this.toggleLinks}>
        <div className='epi-block--inner commonly-searched--actuator-inner show-for-small-only'>
          <div className='commonly-searched--actuator'>
            <div>
              {this.props.title}
            </div>
            <div>
              {!this.state.csLinkToggle && <SVGInline className='icon minus' svg={DownArrow} />}
              {this.state.csLinkToggle && <SVGInline className='icon plus' svg={UpArrow} />}
            </div>
          </div>
        </div>
      </div>
      <div className={`commonly-searched--wrapper ${this.state.csLinkToggle ? 'inactive' : 'active'}`}>
        <div className='epi-block--inner'>
          <div className='commonly-searched--inner'>
            <div className='boxes'>
              <div className='box align-top commonly-searched--infobox hide-for-small-only'>
                <div className='commonly-searched--heading'>{this.props.title}</div>
                <div className='commonly-searched--browse-all-wrapper hide-for-small-only'>
                  <div className='commonly-searched--browse-all-heading'>Can't find what you need?</div>
                  <a href={'https://' + window.location.hostname + this.props.browseAllHref.replace('~', '')} title={this.props.browseAllText} target={this.props.browseAllTarget} className='commonly-searched--browse-all-link link-alt'>{this.props.browseAllText}<SVGInline className='icon chevron' svg={ChevronDown} /></a>
                </div>
              </div>
              <div className='box align-top grow shrink half-plus commonly-searched--terms-block'>
                <div className='commonly-searched--terms-container'>
                  <div>
                    <div className='commonly-searched-header'>{this.props.label}</div>
                    <div className='cslinks-container'>{this.renderLinks()}</div>
                  </div>
                  <div className='commonly-searched-mobile-browse-all'>
                    <div className='commonly-searched--browse-all-heading'>Can't find what you need?</div>
                    <a href={'https://' + window.location.hostname + this.props.browseAllHref.replace('~', '')} title={this.props.browseAllText} target={this.props.browseAllTarget} className='commonly-searched--browse-all-link link-alt'>{this.props.browseAllText}<SVGInline className='icon chevron' svg={ChevronDown} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
