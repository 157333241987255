import React from 'react'
import './select-location-box.scss'

export default class SelectLocationBox extends React.Component {
  render () {
    const { selected, setLocation, setShowSelectLocBox, show, locationList, hasScheduleOnline, adrId } = this.props
    return (
      <div className='select-location-box'>
        <span className={show ? 'shown' : 'hidden'}>

          {
            locationList.map((location, index) => (
              <div key={index} className='location-list-item'>
                <input id={`id-${index}`} className='radio-button' type='radio' name='address' checked={ (!hasScheduleOnline? selected.adrId === location.adrId : selected.departmentId === location.departmentId)} onClick={() => { setLocation(location); setShowSelectLocBox() }} />
                <div className='select-location-item'>
                  <div className='select-location-label'>{location.departmentName}</div>
                  {
                    location.distance
                      ? <div className='select-location-address'>{location.distance} mi - {location.address.street} {location.address.cityStateZip}</div>
                      : <div className='select-location-address'>{location.address.street} {location.address.cityStateZip}</div>
                  }
                </div>
              </div>
            ))
          }

        </span>
      </div>
    )
  }
}
