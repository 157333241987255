export const DEFAULT_STATE = {
  showSchedulingModal: false,
  showLoader: false,
  doctorSelectedLocation: null,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SHOW_SCHEDULING_MODAL':
      return Object.assign({}, state, { showSchedulingModal: true })
    case 'HIDE_SCHEDULING_MODAL':
      return Object.assign({}, state, { showSchedulingModal: false, showLoader: false })
    case 'SHOW_CONTENT':
      return Object.assign({}, state, { contentToShow: action.value })
    case 'SET_DOCTOR_URL':
      return Object.assign({}, state, { doctorUrl: action.value })
    case 'SET_DOCTOR_PHONE':
      return Object.assign({}, state, { doctorPhone: action.value })
    case 'SET_DOCTOR':
      return Object.assign({}, state, { doctor: action.value })
    case 'SET_DOCTOR_SELECTED_LOCATION':
      return Object.assign({}, state, { doctorSelectedLocation: action.value })
  }

  return state
}
