import React, { useEffect, useState } from "react";
import infoIcon from "../../../shared/assets/info.svg";
import SVGInline from "react-svg-inline";
import { scheduleQuestionEvent } from "../../../shared/utility-functions/googleTagManager";
import "./questions-modal.scss";

export default function QuestionsModal(props) {
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [quesAnswers, setQuesAnswers] = useState([]);
  useEffect(() => {
    let answer = document.querySelector("button.answer");
    if (answer) {
      answer.focus();
    }
  }, []);

  useEffect(() => {
    document.activeElement.blur();
  }, [currentQuestion]);

  useEffect(() => {
    setTotalQuestions(props.service.numberOfQuestions);
    setCurrentQuestion(props.service.getCurrentQuestion());
  }, [props.title]);

  const actionHandler = (answer) => {
    const dataLayerObject = {
      flow: props.flow,
      scheduleQuestionNumber: undefined,
      scheduleQuestion: currentQuestion.question,
      scheduleQuestionAnswer: answer.text,
    };

    scheduleQuestionEvent(dataLayerObject);

    if (answer.error) {
      props.submissionHandler(answer);
    } else {
      if (currentQuestion.id >= 5) {
        setQuesAnswers((prev) => [...prev, { id: currentQuestion.id, answer: answer.text }]);
      }
      props.service.answerQuestion(answer);
      const currentQs = props.service.getCurrentQuestion();
      setCurrentQuestion(currentQs);
      if (currentQs === "done") {
        props.submissionHandler({ quesAnswers:[...quesAnswers,{ id: currentQuestion.id, answer: answer.text }] });
      } else {
        let answer = document.querySelector("button.answer");
        if (answer) {
          answer.focus();
        }
      }
    }
  };

  let positionValue = currentQuestion && currentQuestion.number % 2 === 0 ? "relative" : "initial"; // doing this for a safari bug to force a redraw of question text
  return currentQuestion ? (
    <div className={`questions-modal ${props.flow}`}>
      {currentQuestion.type && currentQuestion.type === "notice" && (
        <SVGInline
          svg={infoIcon}
          className='error-icon'
        />
      )}
      <div className='title'>{currentQuestion.title ? currentQuestion.title : props.title}</div>
      {currentQuestion.type !== "notice" && totalQuestions > 1 && (
        <p className='step-numbers'>
          {currentQuestion.number} of {totalQuestions}
        </p>
      )}
      <p
        className='question'
        style={{ position: positionValue }}>
        {currentQuestion.question}
      </p>
      {currentQuestion &&
        currentQuestion.answers &&
        currentQuestion.answers.map((answer, index) => {
          return (
            <button
              key={index}
              className={`btn btn-primary answer question-number-${currentQuestion.number} ${
                answer.className ? answer.className : ""
              } button-${index}`}
              onClick={() => actionHandler(answer)}>
              {answer.text}
            </button>
          );
        })}
    </div>
  ) : (
    <div></div>
  );
}
