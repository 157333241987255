import { createBrowserHistory } from "history";

let base = window.location.pathname;
base = base.replace(/\/$/, "");

if (base.indexOf("thankyou") > -1) {
	base = "/" + base.split("/")[1];
}

const history = createBrowserHistory({
	basename: base,
});

if (window.location.pathname.indexOf("thankyou") > -1) {
	history.push("/thankyou", {});
}
const listener = (location, action) => {
	if (action === "PUSH") {
		window.scroll(0, 0);
	}
	if (action === "REPLACE") {
		window.scroll(0, 0);
	}
	if (action === "POP") {
		window.scroll(0, 0);
	}
};

history.listen(listener);

export { listener };

export default history;
