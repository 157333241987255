import React from 'react'
import { scrollToLocation } from '../../shared/utility-functions'
import OnMyWayButton from './OnMyWayButton.jsx'
import GetCareNowButton from './GetCareNowButton.jsx'

export default class HeaderInfo extends React.Component {
  constructor (props) {
    super(props)
    this.mql = window.matchMedia('(max-width: 767px)')
    this.state = {
      isMobileView: false,
      locationCount: window.sessionStorage.getItem('locationCount') || 0
    }
  }

  componentDidMount() {
    this.setState({ isMobileView: this.mql.matches })
    window.addEventListener("sessionUpdated", (e) => {
      if (this.state.locationCount === 0) {
        this.setState({ locationCount: window.sessionStorage.getItem('locationCount') })
      }
    }) 

    this.mql.addEventListener('change', (e) => {
      this.setState({ isMobileView: e.matches })
    })
  }

  componentWillUnmount () {
    window.removeEventListener("sessionUpdated", (e) => {
      this.setState({ locationCount: window.sessionStorage.getItem('locationCount') })
    }) 

    this.mql.removeEventListener('change', (e) => {
      this.setState({ isMobileView: e.matches })
    })
  }

  desktopView () {
    return <div className='header-block--inner hide-for-small-only'>
      <div className='header-block--card'>
        <div className='header-block--card-top'>
          <div className='header-block--card-content'>
            {this.props.isGraySuppressed === true && !this.state.isMobileView
              ? <h1 className='header-block--heading'>{this.props.menuHeader}</h1>
              : <span className='header-block--heading'>{this.props.menuHeader}</span>
            }
          </div>
          <div className='header-block--card-location'>
            <div className='header-block--card-location--count-wrap clearfix'>
              <div className='header-block--card-location--count'>
                <div>{this.props.locationCount > 1 ? this.props.locationCount + ' Locations' : (this.props.locationCount == 0 ?  this.state.locationCount + ' Locations': this.props.locationCount + 'Location')}</div>
                <a className='header-block--card-location--count-map-link' onClick={() => scrollToLocation('#locationResults')}> View Locations on Map</a>
              </div>
            </div>
          </div>
          { this.props.onMyWayEnabled && <OnMyWayButton /> }
          { this.props.getCareNowEnabled && <GetCareNowButton /> }
        </div>
      </div>
    </div>
  }

  mobileView () {
    return <div className='header-block--inner hide-for-medium-up show-for-small-only'>
      <div className='header-block--card'>
        <div className='header-block--card-top'>
          <div className='header-block--card-content'>
            {this.props.isGraySuppressed === true && this.state.isMobileView
              ? <h1 className='header-block--heading'>{this.props.menuHeader}</h1>
              : <span className='header-block--heading'>{this.props.menuHeader}</span>
            }
          </div>
          <div className='header-block--card-location'>
            <div className='header-block--card-location--count-wrap clearfix'>
              <div className='header-block--card-location--count'>
                <div>{this.props.locationCount > 1 ? this.props.locationCount + ' Locations' : (this.props.locationCount == 0 ?  this.state.locationCount + ' Locations': this.props.locationCount + 'Location')}</div>
                <a className='header-block--card-location--count-map-link' onClick={() => scrollToLocation('#locationResults')}> View Locations on Map</a>
              </div>
            </div>
          </div>
          { this.props.onMyWayEnabled && <OnMyWayButton /> }
          { this.props.getCareNowEnabled && <GetCareNowButton /> }
        </div>
      </div>
    </div>
  }

  render () {
    return (this.props.mobile ? this.mobileView() : this.desktopView())
  }
}
